@tailwind base;
@tailwind components;
@tailwind utilities;
::-webkit-scrollbar {
    width: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100vh;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 100vh;
    border: 3px solid #FFFFFF;
    background: #FFFFFF;
  }
  
  /* Handle on hover */
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

  
  





/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}